import React, { useState, useEffect } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Button, Pagination, Table, Input, Select } from '../../../../components'
import { axios, moment } from '../../../../boot'
import { useNavigate } from 'react-router'
import { useGetPlan } from '../../../../hooks'
import { convertPhone, jsonToXlsx } from '../../../../methods'
const Users = () => {
  const basicParams = JSON.parse(sessionStorage.getItem("params")) ?? {};
  const navigate = useNavigate();
  const [params, setParams] = useState({ page: 1, ...basicParams });
  const [users, setUsers] = useState([])
  const [pages, setPages] = useState({})
  const plans = useGetPlan()
  // console.log("plans : ",plans)
  const saveParams = () => {
    const value = JSON.stringify(params);
    sessionStorage.setItem("params", value);
  };
  const getUsers = () => {
    const url = "/users/getUsers"
    saveParams();
    const config = {
      params: {
        perPage: 20,
        ...params,
      },
    };
    config.params.phone && (config.params.phone = params.phone?.slice(1))
    params.date &&
      ([config.params.startDate, config.params.endDate] = params.date);
    delete config.params.date;
    axios.get(url, config).then(({ data }) => {
      setUsers(data?.data)
      setPages(data?.pages)
    })
  }
  const downloadXlsx = async () => {
    const url ="/users/getUsers";
    const perPage = 100;
    const totalPages = Math.ceil(pages.totalItems / perPage);
    const config = (page) => ({ params: { ...params, perPage, page , paymentMethod : "USD" } });
    const getAllData = await [...Array(totalPages)]
      .map((e, i) => i + 1)
      .map(
        async (page) => await (await axios.get(url, config(page))).data?.data
      );
    const allData = await Promise.all(getAllData);
    // console.log("all Data : " , allData)
    const title = "report";
    
    const json = allData.flat().map((e , index) => ({
      "ردیف" : `${index+1}` ,
      "نام کاربر": `${e?.firstName} ${e?.lastName}`,
      "پلن" : `${planName(e?.plan)}`,
      "شماره موبایل": `${convertPhone(e?.phone)}`,
      "ایمیل" : `${e?.email}`,
      "تاریخ شروع": moment.miladiToShamsi({ date: e.createdAt }),
      "تاریخ انقضا" : `${moment.miladiToShamsi({ date: e.expiresIn })}`, 
      "امتیاز" : `${e?.Score}`,
      "کدمعرف" : `${e?.ReferralCode}`
    }));
    jsonToXlsx({ json, title });
  };
  const planName = (id) => {

    let yourPlan = plans.find(e => e._id === id)
    // console.log(yourPlan)
    return yourPlan?.name
  }
  const formControls = [
    {
      label: "شماره تلفن",
      state: "phone",
      type: "number",
      clear: true,
    },
    {
      tag: Select,
      label: "پلن",
      state: "plan",
      cancelBtn: true,
      items: plans?.map((e) => ({ name: e?.name, id: e?._id }))
    },
    {
      label: "تاریخ",
      state: "date",
      type: "date",
      cancelBtn: true,
    },
  ];
  const handleClick = (e, id) => {
    const target = e.target.id
    if (target === "phone") {
      return
    }
    navigate(id)
  }

  useEffect(getUsers, [params])

  return (
    <Container className="">
      <Row className="d-print-none">
        <Col xs="12">
          <button
            className="bi bi-filetype-xlsx fs-3 text-success"
            onClick={downloadXlsx}
          />
        </Col>
        {formControls.map((e) => (
          <Col key={e.state} xs="12" md="6" lg="4">
            {React.createElement(e.tag ?? Input, {
              ...e,
              value: params[e.state],
              setValue: (val) => {
                setUsers([]);
                setParams((p) => ({ ...p, page: 1, [e.state]: val }));
              },
            })}
          </Col>
        ))}
      </Row>
      <Row className="w-100">
        <Col xs="12" md="12">
          <Table className="text-center">
            <thead>
              <tr>
                <th>نام و نام خانوادگی</th>
                <th>شماره تلفن</th>
                <th>ایمیل</th>
                <th>كد معرف</th>
                <th>امتياز كاربر</th>
                <th>پلن</th>
                <th>تاریخ ثبت نام</th>
                <th>تاریخ انقضا</th>
              </tr>
            </thead>
            <tbody>
              {users?.map((e) => (
                <tr key={e._id} onClick={(event) => handleClick(event, e._id)}>
                  <td>{`${e?.firstName} ${e?.lastName}`}</td>
                  <td id='phone'>{convertPhone(e?.phone)}</td>
                  <td>{e?.email}</td>
                  <td>{e.ReferralCode}</td>
                  <td>{e.Score}</td>
                  <td>{planName(e.plan)}</td>
                  <td>{moment.miladiToShamsi({ date: e.createdAt })}</td>
                  <td>{moment.miladiToShamsi({ date: e.expiresIn })}</td>
                </tr>
              ))}
              <tr>
                <td colSpan="8">
                  <Button
                    onClick={() => navigate("new")}
                    variant="success"
                    className="text-light"
                  >
                    اضافه کردن کاربر جدید
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Pagination
        totalPages={pages.totalPages}
        activePage={params.page}
        setActivePage={(page) => setParams((p) => ({ ...p, page }))}
      />
    </Container>
  )
}

export default Users