import { Navigate } from "react-router";
import { LoginLayout, AdminLayout, LandingLayout, UserLayout } from "../layouts";

import Login from "../pages/LoginLayout/Login";
import VerifyCode from "../pages/LoginLayout/VerifyCode";
import Signup from "../pages/LoginLayout/Signup";
import ResetPassword from "../pages/LoginLayout/ResetPassword";

import Article from "../pages/UserLayout/Article";
import Articles from "../pages/UserLayout/Articles";
import Cart from "../pages/UserLayout/Cart";
import StartOrderUsd from "../pages/UserLayout/StartOrderUsd";

import ContactUs from "../pages/UserLayout/ContactUs";
import Home from "../pages/UserLayout/Home";
import Permit from "../pages/UserLayout/Permit";
import Profile from "../pages/UserLayout/Profile";
import Questions from "../pages/UserLayout/Questions";
import Receipt from "../pages/UserLayout/Receipt";
import TermsAndConditions from "../pages/UserLayout/TermsAndConditions";
import WhatIsMarvel from "../pages/UserLayout/WhatIsMarvel";
import WindowsServerTrade from "../pages/UserLayout/WindowsServerTrade";

import AdminReporting from "../pages/AdminLayout/Reporting";
import AdminProducts from "../pages/AdminLayout/Products";
import AdminProduct from "../pages/AdminLayout/Product";
import AdminActiveServices from "../pages/AdminLayout/ActiveServices";
import AdminActiveService from "../pages/AdminLayout/ActiveService";
import AdminFreeServices from "../pages/AdminLayout/FreeServices";
import AdminUsdOrders from "../pages/AdminLayout/UsdOrders";
import AdminManualBuy from "../pages/AdminLayout/ManualBuy";
import AdminUsdOrder from "../pages/AdminLayout/UsdOrder";
import AdminPlans from "../pages/AdminLayout/Plans";
import AdminPlan from "../pages/AdminLayout/Plan";

import Error from "../pages/Error";
import ActiveServices from "../pages/UserLayout/ActiveServices";
// import Gallery from "../pages/UserLayout/Gallery";
import Report from "../pages/AdminLayout/Report";
import VerifyZarinPayment from "../pages/UserLayout/VerifyZarinPayment";
import Users from "../pages/AdminLayout/MarvelTradePayments/Users";
import User from "../pages/AdminLayout/MarvelTradePayments/User";
import Plans from "../pages/AdminLayout/MarvelTradePayments/Plans";
import Plan from "../pages/AdminLayout/MarvelTradePayments/Plan";
import Purchases from "../pages/AdminLayout/MarvelTradePayments/Purchases";
import Purchase from "../pages/AdminLayout/MarvelTradePayments/Purchase";
import Channels from "../pages/AdminLayout/MarvelTradePayments/Channels";
import Channel from "../pages/AdminLayout/MarvelTradePayments/Channel";
import Signals from "../pages/AdminLayout/MarvelTradePayments/Signals";
import Signal from "../pages/AdminLayout/MarvelTradePayments/Signal";
import Discounts from "../pages/AdminLayout/MarvelTradePayments/Discounts";
import Discount from "../pages/AdminLayout/MarvelTradePayments/Discount";
import EducationCategories from "../pages/AdminLayout/MarvelTradePayments/EducationCategories";
import EducationCategory from "../pages/AdminLayout/MarvelTradePayments/EducationCategory";
import NewsCategories from "../pages/AdminLayout/MarvelTradePayments/NewsCategories";
import NewsCategory from "../pages/AdminLayout/MarvelTradePayments/NewsCategory";
import News from "../pages/AdminLayout/MarvelTradePayments/News";
import NewsDetails from "../pages/AdminLayout/MarvelTradePayments/NewsDetails";
import Educations from "../pages/AdminLayout/MarvelTradePayments/Educations";
import Education from "../pages/AdminLayout/MarvelTradePayments/Education";
import AdminUser from "../pages/AdminLayout/AdminUser";
import PrivateChannel from "../pages/AdminLayout/MarvelTradePayments/PrivateChannel";

const routes = (isLogged = false, role = null) => {
  const checkRole = (roles = []) =>
    isLogged && role !== null && roles.includes(role);

  return [
    {
      path: "/",
      element: <LandingLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        // {
        //   path: "terms-and-conditions",
        //   element: <TermsAndConditions />,
        // },
        {
          path: "windows-server-trade",
          element: <WindowsServerTrade />,
        },
        // {
        //   path: "articles",
        //   element: <Articles />,
        // },
        // {
        //   path: "articles/:id",
        //   element: <Article />,
        // },
        {
          path: "what-is-marvel",
          element: <WhatIsMarvel />,
        },
        // {
        //   path: "gallery",
        //   element: <Gallery />,
        // },
        {
          path: "questions",
          element: <Questions />,
        },
        {
          path: "permit",
          element: <Permit />,
        },
        {
          path: "contact-us",
          element: <ContactUs />,
        },
        {
          path: "receipt",
          element: <Receipt />,
        },

      ],
    },
    {
      path: "/",
      element: checkRole(["client", "0o(MuYB$pv", "unregistered" , "0aZ03@W.h!"]) ? (
        <UserLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        {
          path: "cart",
          element: <Cart />,
        },
        {
          path: "start-order-usd",
          element: <StartOrderUsd />,
        },
        {
          path: "profile",
          element: <Profile /> ,
        },
        {
          path: "active-services",
          element: <ActiveServices />
        },
        {
          path: "windows-server-trade",
          element: <WindowsServerTrade />,
        },
        {
          path: "articles",
          element: <Articles />,
        },
        {
          path: "articles/:id",
          element: <Article />,
        },
        {
          path: "what-is-marvel",
          element: <WhatIsMarvel />,
        },
        {
          path: "questions",
          element: <Questions />,
        },
        {
          path: "permit",
          element: <Permit />,
        },
        {
          path: "contact-us",
          element: <ContactUs />,
        },
        {
          path: "receipt",
          element: <Receipt />,
        },
        {
          path: "verifyzarinPayment",
          element: <VerifyZarinPayment />,
        },
      ]
    },
    {
      path: "/admin",
      element: checkRole(["0o(MuYB$pv" , "0aZ03@W.h!"]) ? (
        <AdminLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children: [
        {
          path: "",
          element: <Navigate to="products" replace />,
        },
        {
          path: "reporting",
          element: <AdminReporting />,
        },
        {
          path: "reporting/:id",
          element: <Report />,
        },
        {
          path: "products",
          element: <AdminProducts />,
        },
        {
          path: "products/:id",
          element: <AdminProduct />,
        },
        {
          path: "active-services",
          element: <AdminActiveServices />,
        },
        {
          path: "active-services/:id",
          element: <AdminActiveService />,
        },
        {
          path: "free-services",
          element: <AdminFreeServices />,
        },
        {
          path: "manual-buy",
          element: <AdminManualBuy />,
        },
        {
          path: "usd-orders",
          element: <AdminUsdOrders />,
        },
        {
          path: "usd-orders/:id",
          element: <AdminUsdOrder />,
        },
        // {
        //   path: "plans",
        //   element: <AdminPlans />,
        // },
        // {
        //   path: "plans/:id",
        //   element: <AdminPlan />,
        // },
        {
          path: "marvel-users",
          element: <Users />,
        },
        {
          path: "marvel-users/:id",
          element: <User />,
        },
        {
          path: "marvel-plans",
          element: <Plans />,
        },
        {
          path: "marvel-plans/:id",
          element: <Plan />,
        },
        {
          path: "marvel-purchases",
          element: <Purchases />,
        },
        {
          path: "marvel-purchases/:id",
          element: <Purchase />,
        },
        {
          path: "marvel-channels",
          element: <Channels />,
        },
        {
          path: "marvel-channels/:id",
          element: <Channel />,
        },
        {
          path: "marvel-signals",
          element: <Signals />,
        },
        {
          path: "marvel-signals/:id",
          element: <Signal />,
        },
        {
          path: "marvel-discounts",
          element: <Discounts />,
        },
        {
          path: "marvel-discounts/:id",
          element: <Discount />,
        },
        {
          path: "marvel-educationCat",
          element: <EducationCategories />,
        },
        {
          path: "marvel-educationCat/:id",
          element: <EducationCategory />,
        },
        {
          path: "marvel-newsCat",
          element: <NewsCategories />,
        },
        {
          path: "marvel-newsCat/:id",
          element: <NewsCategory />,
        },
        {
          path: "marvel-news",
          element: <News />,
        },
        {
          path: "marvel-news/:id",
          element: <NewsDetails />,
        },
        {
          path: "marvel-educations",
          element: <Educations />,
        },
        {
          path: "marvel-educations/:id",
          element: <Education />,
        },
        {
          path: "adminUser",
          element: <AdminUser />,
        },
        {
          path: "marvel-private-channel",
          element: <PrivateChannel />,
        },
      ],
    },
    {
      path: "/login",
      element: <LoginLayout />,
      children: [
        {
          path: "",
          element: <Login />,
        },
        // {
        //   path: "verify-code",
        //   element: <VerifyCode />,
        // },
        {
          path: "sign-up",
          element: <Signup />,
        },
        {
          path: "reset-password",
          element: <ResetPassword />,
        },
      ],
    },
    {
      path: "*",
      element: <Error title="صفحه مورد نظر یافت نشد!" />,
    },
  ];
};
export default routes;
